
import img1 from '../images/img/img1.jpg';
import img2 from '../images/img/img2.jpg';
import img3 from '../images/img/img3.jpg';
import img4 from '../images/img/img4.jpg';
import img5 from '../images/img/img5.jpg';
import img6 from '../images/img/img6.jpg';
import img7 from '../images/img/img7.jpg';
import img8 from '../images/img/img8.jpg';
import img9 from '../images/img/img9.jpg';
import img10 from '../images/img/img10.jpg';
import img11 from '../images/img/img11.jpg';
import img12 from '../images/img/img12.jpg';
import img13 from '../images/img/img13.jpg';
import img14 from '../images/img/img14.jpg';
import img15 from '../images/img/img15.jpg';
import img16 from '../images/img/img16.jpg';
import img17 from '../images/img/img17.jpg';

import img19 from '../images/img/img19.jpg';
import img20 from '../images/img/img20.jpg';
import img21 from '../images/img/img21.jpg';



// eslint-disable-next-line
export default [
    {
        sys: {
            id: "1",

        },
        fields: {
            name: "",
            src: img1
        }
    }, {
        sys: {
            id: "2",

        },
        fields: {
            name: "",
            src: img2
        }
    },
    {
        sys: {
            id: "3",

        },
        fields: {
            name: "",
            src: img3
        }
    },
    {
        sys: {
            id: "4",

        },
        fields: {
            name: "",
            src: img4
        }
    },
    {
        sys: {
            id: "5",

        },
        fields: {
            name: "",
            src: img5
        }
    },
    {
        sys: {
            id: "6",

        },
        fields: {
            name: "",
            src: img6
        }
    },
    {
        sys: {
            id: "7",

        },
        fields: {
            name: "",
            src: img7
        }
    },
    {
        sys: {
            id: "8",

        },
        fields: {
            name: "",
            src: img8
        }
    },
    {
        sys: {
            id: "9",

        },
        fields: {
            name: "",
            src: img9
        }
    },
    {
        sys: {
            id: "10",

        },
        fields: {
            name: "",
            src: img10
        }
    },
    {
        sys: {
            id: "11",

        },
        fields: {
            name: "",
            src: img11
        }
    },
    {
        sys: {
            id: "12",

        },
        fields: {
            name: "",
            src: img12
        }
    },
    {
        sys: {
            id: "13",

        },
        fields: {
            name: "",
            src: img13
        }
    },
    {
        sys: {
            id: "14",

        },
        fields: {
            name: "",
            src: img14
        }
    },
    {
        sys: {
            id: "15",

        },
        fields: {
            name: "",
            src: img15
        }
    },
    {
        sys: {
            id: "16",

        },
        fields: {
            name: "",
            src: img16
        }
    },
    {
        sys: {
            id: "17",

        },
        fields: {
            name: "",
            src: img17
        }
    },
    {
        sys: {
            id: "19",

        },
        fields: {
            name: "",
            src: img19
        }
    },
    {
        sys: {
            id: "20",

        },
        fields: {
            name: "",
            src: img20
        }
    },
    {
        sys: {
            id: "21",

        },
        fields: {
            name: "",
            src: img21
        }
    }
]