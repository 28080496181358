import img1 from '../images/hristo-portrets/Hristo-Tanev-Probudenica.jpg';
import img2 from '../images/hristo-portrets/portret-hristo-tanev.jpg';




// eslint-disable-next-line
export default [
    {
        sys: {
            id: "1",
            isShow: false
        },
        fields: {
            name: "Hristo Tanev porteret",
            src: img1
        }
    }, {
        sys: {
            id: "2",
            isShow: false
        },
        fields: {
            name: "Hristo Tanev porteret",
            src: img2
        }
    }, {
        sys: {
            id: "3"
        },
        fields: {
            name: "paragraph three",
            text: `Роден е на 21 септември 1943 година в град Стара Загора.
Като ученик учи в старозагорската гимназия „Христо Ботев“.
`
        }
    }, {
        sys: {
            id: "4"
        },
        fields: {
            name: "paragraph four",
            text: `Като ученик учи в старозагорската гимназия „Христо Ботев“.
През 1970 г. завършва с отличие Великотърновския университет "Св. св. Кирил и Методий" - специалност "Стенопис" при проф. Никола Гелов. Негови преподаватели са били известните български художници проф. Васил Стоилов и проф. Никола Кожухаров.
`
        }
    }, {
        sys: {
            id: "5"
        },
        fields: {
            name: "paragraph five",
            text: `Танев твори основно в областта на живописта, архитектурния, промишлен и графичен дизайн, пространственото оформление и рекламата. Има реализирани над тридесет монументални проекта с класически и съвременни техники. В областта на пространственото оформление авторът има осъществена поредица от интериорни и екстериорни задачи, както и реализации, свързани с графичния дизайн и рекламата. Негово дело са гербовете на Стара Загора, Димитровград, Чирпан, Гълъбово и на селата Кърнаре и Сенник. Има утвърдени над 500 запазени знака на фирми, организации и нови производства.`
        }
    }, {
        sys: {
            id: "6"
        },
        fields: {
            name: "paragraph six",
            text: `Излага свои картини в самостоятелни изложби и в чужбина (Берлин - 2006 г., Брюксел - 2008 г.), а в общи представителни изложби и в Москва, Улан Батор, Берлин, Осло, Букурещ, Самара, Катовице, Легницa и др.  Негови картини са част от колекциите на Пушкиновския музей в Москва - "Государственного музея А.С. Пушкина", Берлинската държавна галерия, Монголската държавна галерия, в САЩ, Англия и в почти всички страни в Европа, в частни колекции и 32 държавни галерии.`
        }
    }, {
        sys: {
            id: "7"
        },
        fields: {
            name: "paragraph seven",
            text: `От средата на 1990-те се задълбочава интересът на Танев в областта на хералдиката и семиотиката в търсене на смисъла на символи, свързани с идентичността на българския народ. През 1997 г. излиза книгата „Азът на българите и националните символи“, в чийто авторски колектив е и Христо Танев.`
        }
    }, {
        sys: {
            id: "8"
        },
        fields: {
            name: "paragraph eight",
            text: `От 1976 г. той членува в Съюза на българските художници, а от 1989 г. – в Асоциацията за пластични изкуства към ЮНЕСКО. През 2003 година основава собствена галерия за изящни и приложни изкуства в Стара Загора.`
        }
    }, {
        sys: {
            id: "9"
        },
        fields: {
            name: "paragraph nine",
            text: `Негови творби са притежание на Националната художествена галерия, музея „А. С. Пушкин“ в Москва, над 30 държавни галерии в България и чужбина, като и частни сбирки в България, Европа, Канада, Кувейт, Монголия, САЩ, ЮАР, Япония.`
        }
    }, {
        sys: {
            id: "10"
        },
        fields: {
            name: "paragraph ten",
            text: `Умира на 21 март 2019 г. в Стара Загора.`
        }
    }, {
        sys: {
            id: "11"
        },
        fields: {
            name: "paragraph eleven",
            text: `(акростих)`
        }
    }, {
        sys: {
            id: "12"
        },
        fields: {
            name: "paragraph twelfe",
            text: `("Азът на българите и националните символи"-
Христо Танев и колектив)`
        }
    }, {
        sys: {
            id: "13"
        },
        fields: {
            name: "paragraph twelfe",
            text: 'Аз - в началото бе слово, '
        }
    }, {
        sys: {
            id: "14"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Зов до българи отново.`
        }
    }, {
        sys: {
            id: "15"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Ъгъл, част от таз Вселена`
        }
    }, {
        sys: {
            id: "16"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Танев претвори в арена`
        }
    }, {
        sys: {
            id: "17"
        },
        fields: {
            name: "paragraph twelfe",
            text: `На един всевечен прочит,    `
        }
    }, {
        sys: {
            id: "18"
        },
        fields: {
            name: "paragraph twelfe",
            text: `А светът отвръща с почит.`
        }
    }, {
        sys: {
            id: "19"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Българите Бог беляза,    `
        }
    }, {
        sys: {
            id: "20"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Ъгъл свят да имат - Аза.`
        }
    }, {
        sys: {
            id: "21"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Лъч в очите им да свети,    `
        }
    }, {
        sys: {
            id: "22"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Гордо да пониква цвете,    `
        }
    }, {
        sys: {
            id: "23"
        },
        fields: {
            name: "paragraph twelfe",
            text: `А и днес с дъга позната,    `
        }
    }, {
        sys: {
            id: "24"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Ражда се красива дата.`
        }
    }, {
        sys: {
            id: "25"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Идва вред щастлива ера,    `
        }
    }, {
        sys: {
            id: "26"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Тя е близо не от вчера:    `
        }
    }, {
        sys: {
            id: "27"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Ек от светла Божа фраза        `
        }
    }, {
        sys: {
            id: "28"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Христо Танев вплете в "Аза"...    `
        }
    }, {
        sys: {
            id: "29"
        },
        fields: {
            name: "paragraph twelfe",
            text: `Автор: Иван Паскалев,
    Стара Загора `
        }
    }
]