// eslint-disable-next-line
export default [
    {
        sys: {
            id: "1"
        },
        fields: {
            name: "paragraph one",
            text: `На 30 юли 2020 г. в Лапидариума на Регионален исторически музей, в град Стара Загора се проведе учредително събрание за създаване на Народно читалище, което да носи името на художника-хералдик Христо Танев.
По покана на Инициативния комитет, който обмисляше идеята още от месец февруари, присъстваха 160 души.Те одобриха предложенията за Устав на читалището и Управителен съвет, както и основните цели за създаването на доброволната културна институция.`


        }
    }, {
        sys: {
            id: "2"
        },
        fields: {
            name: "paragraph two",
            text: `Сред учредителите са семейството на Христо Танев – синовете му Николай и Огнян Таневи, поетът Таньо Клисуров, д-р Дунев, Върбан Колев, Димитър Брацов, Трифон Митев, Пеньо Калчев, Иван Танев и др.
Намерението на учредителите е Читалище „Христо Танев“ да не бъде ординерно. Идеите са за нови, специфични форми на работа, които да продължат и развият интересите и откритията на Христо Танев за българското достолепие. От 2003 г. неговата художествена галерия „Алба-Авитохол“, която се намира на бул. „Митрополит Методий Кусев“ No51, се превърна в културно-научен център на негови съмишленици от цялата страна. Всички сбирки се предшестваха с отслужване на православен ритуал по освещаване на хляба и виното.
`
        }
    }, {
        sys: {
            id: "3"
        },
        fields: {
            name: "paragraph three",
            text: `Христо Танев е роден на 21.09.1943 г. в гр. Стара Загора. Завършва Великотърновския университет “Св. св. Кирил и Методий”, специалност стенопис, при професор Никола Гелов. Негови преподаватели са известните български художници проф. Васил Стоилов и проф. Никола Кожухаров. През 1970 г. защитава с отличие дипломната си работа – Многофигурна монументална композиция “Тракийски мотиви”. За това безспорно художествено постижение е удостоен с почетния знак на Университета.`
        }
    }, {
        sys: {
            id: "4"
        },
        fields: {
            name: "paragraph four",
            text: `Художникът работи главно в областта на пространственото оформление, архитектурния, промишлен и графичен дизайн и реклама. Негово дело са гербовете и знамената на Стара Загора, Димитровград, Чирпан и Гълъбово, на много училища и организации. Има утвърдени над 500 запазени знака на фирми, организации и нови производства. Участва в представителни изложби на българското изобразително изкуство зад граница. Член е на Съюза на българските художници и Асоциацията за пластични изкуства към ЮНЕСКО. Негови творби са притежание на Националната художествена галерия, музея „А. С. Пушкин” в Москва, над 30 държавни галерии в България и чужбина, както и частни сбирки в България, Европа, Канада, Кувейт, Монголия, САЩ, ЮАР, Япония. През 1997 г. той, заедно със специалисти и изследователи от обществото ЗИЕЗИКАНАЗ, издава книгата “Азът на българите и националните символи”.
След кратко боледуване умира на 21 март 2019 г. в Стара Загора.
`
        }
    }, {
        sys: {
            id: "5"
        },
        fields: {
            name: "paragraph five",
            text: `Официализирането на читалището е дълга и сложна процедура. Предстоящо е вписване в Агенцията по вписванията, решение на Министерство на културата и пр.
Важно е, че са направени първите крачки за продължаване и надграждане на делото на един достоен българин, за когото винаги на първо място е бил родният му град Стара Загора.
Росица Ранчева`
        }
    },
]