import React, { Component } from 'react'
import UnderConstruction from '../components/underConstruction';
export default class library extends Component {
    render() {
        return (
            <div>
                <UnderConstruction />
            </div>
        )
    }
}
