import img1 from '../images/hristo-pictures/Hristo-Tanev-Zakrilnikyt na Obetowanata zemq.jpg';
// eslint-disable-next-line
export default [
    {
        sys: {
            id: "1",
            isShow: true
        },
        fields: {
            name: "The protector of the promised land",
            src: img1
        }
    }
]