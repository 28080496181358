import img1 from '../images/membership/zavlenie-image.png'
import img2 from '../images/membership/ustav-image.png'
import img3 from '../images/membership/udostoverenie-image.png'

// eslint-disable-next-line
export default [
    {
        sys: {
            id: "1",

        },
        fields: {
            name: "Заявление",
            src: img1
        }
    }, {
        sys: {
            id: "2",

        },
        fields: {
            name: "Устав",
            src: img2
        }
    },
    {
        sys: {
            id: "3",

        },
        fields: {
            name: "Удостоверение",
            src: img3
        }
    }
]